import styled from 'styled-components';

export const Container = styled.div`
  @media screen and (max-width: 890px) {
    margin-bottom: 50px;
    .desktopNav {
      display: none;
    }
    .mobileNav {
      position: fixed;
      z-index: 10;
      width: 100%;
    }
  }
  @media screen and (min-width: 891px) {
    margin-bottom: 50px;
    .mobileNav {
      display: none;
    }
    .desktopNav {
      position: fixed;
      z-index: 10;
      width: 100%;

      a,
      .ProductMenu {
        color: white;
        text-decoration: none;

        &:hover {
          color: #00739d;
        }
      }
    }
  }
`;
