import React from 'react';
import {
  FaTwitterSquare,
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaYoutube,
  FaRegCopyright,
} from 'react-icons/fa';
import PropTypes from 'prop-types';
import { StaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import { Box, Anchor, Text, ThemeContext } from 'grommet';

const customTheme = {
  global: {
    focus: {
      border: {
        color: 'black',
      },
    },
  },
};

const Footer = ({ socialLinks, address, title, mainLogo }) => (
  <ThemeContext.Extend value={customTheme}>
    <Box background="black" fill="width" style={{ zIndex: '1' }}>
      <Box direction="row-responsive" alignSelf="center" gap="xlarge">
        <Box direction="column" margin="medium" alignSelf="center">
          <Box width="150px">
            <Img
              fluid={mainLogo ? mainLogo.childImageSharp.fluid : title}
              alt={title}
            />
          </Box>

          <Box direction="column" margin="small">
            <Text>{address.line1}</Text>
            <Text>{address.line2}</Text>
            <Text>{address.country}</Text>
          </Box>
        </Box>

        <Box direction="column" margin="medium" alignSelf="center">
          <Box direction="row" alignSelf="center" margin="small">
            <Anchor
              icon={<FaTwitterSquare size={30} color="white" />}
              href={socialLinks.twitter}
              a11yTitle="Twitter"
              rel="noopener noreferrer"
              target="_blank"
            />
            <Anchor
              icon={<FaFacebook size={30} color="white" />}
              href={socialLinks.facebook}
              a11yTitle="Facebook"
              rel="noopener noreferrer"
              target="_blank"
            />
            <Anchor
              icon={<FaInstagram size={30} color="white" />}
              href={socialLinks.instagram}
              a11yTitle="Instagram"
              rel="noopener noreferrer"
              target="_blank"
            />
            <Anchor
              icon={<FaLinkedin size={30} color="white" />}
              href={socialLinks.linkedIn}
              a11yTitle="LinkedIn"
              rel="noopener noreferrer"
              target="_blank"
            />
            <Anchor
              icon={<FaYoutube size={30} color="white" />}
              href={socialLinks.youtube}
              a11yTitle="YouTube"
              rel="noopener noreferrer"
              target="_blank"
            />
          </Box>
          <Text textAlign="center">
            Copyright <FaRegCopyright /> 2017. All Rights Reserved. Patents
            Pending. | <Link to="/termsAndConditions">Terms & Conditions</Link>
          </Text>
        </Box>
      </Box>
    </Box>
  </ThemeContext.Extend>
);

Footer.propTypes = {
  socialLinks: PropTypes.objectOf(PropTypes.string),
  address: PropTypes.objectOf(PropTypes.string),
  title: PropTypes.string,
  mainLogo: PropTypes.object.isRequired,
};

const FooterWithQuery = () => (
  <StaticQuery
    query={graphql`
      query FooterQuery {
        site {
          siteMetadata {
            siteTitle
            socialLinks {
              twitter
              facebook
              instagram
              linkedIn
              youtube
            }
            address {
              title
              line1
              line2
              country
            }
          }
        }
        logoJson {
          title
          mainLogo {
            childImageSharp {
              fluid(maxWidth: 200, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    `}
    render={data => <Footer {...data.site.siteMetadata} {...data.logoJson} />}
  />
);

export default FooterWithQuery;
