import styled from 'styled-components';

export const Container = styled.nav`
  /* Position and sizing of burger button */
  .bm-burger-button {
    position: absolute;
    width: 30px;
    height: 25px;
    color: white;
    top: 10px;
    left: 10px;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: white;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }

  /* General sidebar styles */
  .bm-menu {
    background: #ededed;
    padding: 0;
    font-size: 1.1em;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: #000;
    padding-top: 0.8em;
    padding-right: 0em;
    padding-bottom: 0.8em;
    padding-left: 0em;
    a:active {
      background-color: silver;
    }
  }

  /* Individual item */
  .bm-item {
    display: inline-block;
    padding: 1.5rem;
    color: black;
    ul {
      display: flex;
      li {
        padding-right: 1rem;
      }
    }
  }

  /* Styling of overlay */
  .bm-overlay {
    background: #ededed;
    position: fixed;
    opacity: 0.8;
    width: 100%;
    height: 100%;
    top: 40px;
    left: 0px;
    z-index: 1000;
  }

  .bm-menu-wrap {
    bottom: 0;
    left: 0;
    top: 45px;
  }
`;
