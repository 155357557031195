import React from 'react';
import PropTypes from 'prop-types';
import Head from 'components/head';
import ResponsiveNav from 'components/responsiveNav/responsiveNav.js';
import GlobalStyle from 'global.css.js';
import Footer from 'components/footer';
import styled from 'styled-components';
import { Grommet, grommet } from 'grommet';

const Container = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  .siteContent {
    flex-grow: 1;
  }
`;

const Layout = ({ children }) => (
  <Grommet theme={grommet}>
    <Container>
      <GlobalStyle />
      <Head />
      <ResponsiveNav />
      <div className="siteContent">{children}</div>
      <Footer />
    </Container>
  </Grommet>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
