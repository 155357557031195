import React from 'react';
import PropTypes from 'prop-types';

import Img from 'gatsby-image';
import Sidebar from 'components/sidebar';

import { IoIosCart } from 'react-icons/io';

import { Box, Menu, Text, Button, ThemeContext } from 'grommet';

import { navigate, StaticQuery, graphql, Link } from 'gatsby';


const MobileNav = ({ data }) => (
  <Box
    direction="row"
    fill="horizontal"
    background={{
      color: 'dark-1',
      opacity: 0.95,
    }}
  >
    <Box margin={{ left: 'small' }}>
      <Sidebar />
    </Box>
    <Box margin="auto">
      <Link to="/">
        <Box width="175px">
          <Img
            fluid={
              data.logoJson.mainLogo
                ? data.logoJson.mainLogo.childImageSharp.fluid
                : data.logoJson.title
            }
            alt={data.logoJson.title}
          />
        </Box>
      </Link>
    </Box>
    
    <Box alignSelf="center" margin={{ left: '0', right: '0' }}>
        <Button
          className="snipcart-checkout"
          icon={<IoIosCart size={30} color="white" />}
          hoverIndicator
        />
      </Box>
    
  </Box>
);

MobileNav.propTypes = {
  data: PropTypes.object.isRequired,
};

const MobileNavWithQuery = () => (
  <StaticQuery
    query={graphql`
      query MobileQuery {
        logoJson {
          title
          mainLogo {
            childImageSharp {
              fluid(maxWidth: 200, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    `}
    render={data => <MobileNav data={data} />}
  />
);

export default MobileNavWithQuery;
