import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { Container } from './sidebar.css';
import { MdHome, MdPhone, MdHearing } from 'react-icons/md';
import { FaDesktop, FaAddressCard } from 'react-icons/fa';
import {FaUserGraduate} from 'react-icons/fa'
import { Box, Text } from 'grommet';

import {FaGlobe} from 'react-icons/fa'

const Row = ({ link, label, children }) => (
  <Box alignSelf="center">
    <Link to={link} style={{ textDecoration: 'none', margin: '0 0 15px 15px' }}>
      <Box
        alignSelf="start"
        alignContent="start"
        direction="row"
        fill="horizontal"
      >
        <Box alignSelf="start">{children}</Box>
        <Text alignSelf="start" margin={{ left: 'small' }}>
          {label}
        </Text>
      </Box>
    </Link>
  </Box>
);

Row.propTypes = {
  link: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const Products = [
  { name: 'OtoSim 2™', link: '/otosim-2' },
  { name: 'OtoSim Mobile™ (Buy Now)', link: '/otosim-mobile' },
  { name: 'OphthoSim™', link: '/ophthosim' },
  { name: 'OphthoSim Mobile™ (Buy Now)', link: '/ophthosim-mobile' },
  { name: 'Mobile Educators Portals™', link: '/ophthosim-educators-portal' },
  { name: 'PneumatoSim™', link: '/pneumatosim' },
  { name: 'Educators Toolkit™', link: '/educators-toolkit' },
];

const ProductsMenu = () => (
  <Box alignSelf="center" style={{ margin: '0 0 8px 15px' }}>
    <Box
      alignSelf="start"
      alignContent="start"
      direction="row"
      fill="horizontal"
    >
      <Box alignSelf="start">
        <MdHearing size={20} />
      </Box>
      <Text alignSelf="start" margin={{ left: 'small' }}>
        Products
      </Text>
    </Box>
    <Box direction="column" alignSelf="center" margin={{ bottom: 'medium' }}>
      {Products.map((product, i) => (
        <Link
          key={i}
          to={product.link}
          style={{ textDecoration: 'none', margin: '3px 0 4px 0' }}
        >
          <Text size="small">{product.name}</Text>
        </Link>
      ))}
    </Box>
  </Box>
);

const Sidebar = () => (
  <Container>
    <Menu className="bm-menu" width={'250px'}>
      <Row link="/" label="Home">
        <MdHome size={20} />
      </Row>
      <Row link="/about" label="About">
        <FaAddressCard size={20} />
      </Row>
      <ProductsMenu />
      
      <Row link="/our-clients" label="Clients">
        <FaGlobe size={20} />
      </Row>
      
      
      <Row link="/publications" label="Publications">
        <FaUserGraduate size={20} />
      </Row>
      
      
      
      
      
      <Row link="/support" label="Support">
        <FaDesktop size={20} />
      </Row>
      <Row link="/contact" label="Contact">
        <MdPhone size={20} />
      </Row>
    </Menu>
  </Container>
);
export default Sidebar;
