import React from 'react';
import PropTypes from 'prop-types';
import { Box, Menu, Text, Button, ThemeContext } from 'grommet';
import { IoIosCart } from 'react-icons/io';
import { navigate, StaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';

const customTheme = {
  global: {
    focus: {
      border: {
        color: 'inherit',
      },
    },
  },
};

const DesktopNav = ({ data }) => (
  <ThemeContext.Extend value={customTheme}>
    <Box
      direction="row"
      fill="horizontal"
      background={{
        color: 'dark-1',
        opacity: 0.95,
      }}
    >
      <Box margin={{ left: 'small' }}>
        <Link to="/">
          <Box width="200px">
            <Img
              fluid={
                data.logoJson.mainLogo
                  ? data.logoJson.mainLogo.childImageSharp.fluid
                  : data.logoJson.title
              }
              alt={data.logoJson.title}
            />
          </Box>
        </Link>
      </Box>
      <Menu
        className="ProductMenu"
        label="PRODUCTS"
        margin={{ left: '12px' }}
        icon={false}
        dropAlign={{ top: 'bottom', left: 'left' }}
        items={[
          {
            label: 'OtoSim 2™',
            onClick: () => {
              navigate('/otosim-2');
            },
          },
          {
            label: 'OphthoSim™',
            onClick: () => {
              navigate('/ophthosim');
            },
          },
          {
            label: 'OtoSim Mobile™',
            onClick: () => {
              navigate('/otosim-mobile');
            },
          },
          {
            label: 'OphthoSim Mobile™',
            onClick: () => {
              navigate('/ophthosim-mobile');
            },
          },
          {
            label: 'Mobile Educators Portals™',
            onClick: () => {
              navigate('/ophthosim-educators-portal');
            },
          },
          {
            label: 'PneumatoSim™',
            onClick: () => {
              navigate('/pneumatosim');
            },
          },
          {
            label: 'Educators Toolkit™',
            onClick: () => {
              navigate('/educators-toolkit');
            },
          },
        ]}
      />
      <Box alignSelf="center" margin={{ left: '17px' }}>
        <Link to="/about">
          <Text>ABOUT</Text>
        </Link>
      </Box>
      
      <Box alignSelf="center" margin={{ left: '17px' }}>
        <Link to="/our-clients">
          <Text>CLIENTS</Text>
        </Link>
      </Box>
      
      
      <Box alignSelf="center" margin={{ left: '17px' }}>
        <Link to="/publications">
          <Text>PUBLICATIONS</Text>
        </Link>
      </Box>
      
      
      
      
      
      <Box alignSelf="center" margin={{ left: '20px' }}>
        <Link to="/support">
          <Text>SUPPORT</Text>
        </Link>
      </Box>
      <Box alignSelf="center" margin={{ left: '20px' }}>
        <Link to="/contact">
          <Text>CONTACT</Text>
        </Link>
      </Box>
      <Box alignSelf="center" margin={{ left: 'auto', right: '30px' }}>
        <Button
          className="snipcart-checkout"
          icon={<IoIosCart size={30} color="white" />}
          hoverIndicator
        />
      </Box>
    </Box>
  </ThemeContext.Extend>
);

DesktopNav.propTypes = {
  data: PropTypes.object.isRequired,
};

const DesktopNavWithQuery = () => (
  <StaticQuery
    query={graphql`
      query DesktopQuery {
        logoJson {
          title
          mainLogo {
            childImageSharp {
              fluid(maxWidth: 200, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    `}
    render={data => <DesktopNav data={data} />}
  />
);

export default DesktopNavWithQuery;
