import React from 'react';
import { Container } from './responsiveNav.css';
import DesktopNav from 'components/responsiveNav/desktop.js';
import MobileNav from 'components/responsiveNav/mobile.js';

const ResponsiveNav = () => (
  <Container>
    <div className="mobileNav">
      <MobileNav />
    </div>
    <div className="desktopNav">
      <DesktopNav />
    </div>
  </Container>
);

export default ResponsiveNav;
